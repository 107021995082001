import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';

import { httpInterceptorsProviders } from './auth/interceptor.index';
import { AuthGuardService } from './auth/auth-guard.service';
import { ListFilterPipe } from '@shared/pipes/list-filter.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { ProgressBarService } from '@shared/components/progressbar/progressbar.service';

@NgModule({
  declarations: [ListFilterPipe, ConfirmDialogComponent, ProgressbarComponent],
  entryComponents: [ConfirmDialogComponent, ProgressbarComponent],
  exports: [ListFilterPipe, ProgressbarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  providers: [httpInterceptorsProviders, AuthGuardService, ProgressBarService]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [ProgressBarService]
    };
  }
}
