import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_FORMATS, NativeDateAdapter, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgModule, Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  format (date: Date, displayFormat: any): string {
    return displayFormat === 'input' ? new Intl.DateTimeFormat('fr-FR').format(date) : this._displayDates(date, displayFormat);
  }

  private _displayDates (date: Date, options: any): any {
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  }
}

const TEDateFormat = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { month: 'numeric', year: 'numeric', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
  }
};

@NgModule({
    exports: [
      MatIconModule,
      MatCardModule,
      MatSidenavModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatToolbarModule,
      MatExpansionModule,
      MatDialogModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatMenuModule,
      MatProgressBarModule,
      MatBadgeModule,
      MatTableModule,
      MatCheckboxModule,
      MatRadioModule,
      MatDatepickerModule,
      MatSlideToggleModule,
      MatChipsModule,
      MatListModule,
      MatPaginatorModule,
      MatSortModule,
      MatDividerModule,
      MatNativeDateModule,
      MatTabsModule,
      MatProgressSpinnerModule
    ],
    imports: [
      MatIconModule,
      MatCardModule,
      MatSidenavModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatToolbarModule,
      MatExpansionModule,
      MatDialogModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatMenuModule,
      MatProgressBarModule,
      MatBadgeModule,
      MatTableModule,
      MatCheckboxModule,
      MatRadioModule,
      MatDatepickerModule,
      MatSlideToggleModule,
      MatChipsModule,
      MatListModule,
      MatPaginatorModule,
      MatSortModule,
      MatDividerModule,
      MatNativeDateModule,
      MatTabsModule,
      MatProgressSpinnerModule
    ],
    providers: [
      {
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
        useValue: { duration: 4000, horizontalPosition: 'end', verticalPosition: 'top', panelClass: ['mat-snack-bar-success'] }
      },
      { provide: DateAdapter, useClass: CustomDateAdapter },
      { provide: MAT_DATE_FORMATS, useValue: TEDateFormat }
    ],
  })

export class MaterialModule { }
