import { Resource } from '@shared/resources/resource';

export class Partner extends Resource {
  name: string;
  id: string;

  constructor (json: any) {
    super();
    this.name = json.name;
    this.id = json.id;
  }

}
