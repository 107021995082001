export const apiRoutes = {
  'localhost': 'https://dv360-wrapper.uat.tradelab-apps.com',
  'dv360-wrapper-admin.dev.tradelab-apps.com': '//dv360-wrapper.dev.tradelab-apps.com',
  'dv360-wrapper-admin.uat.tradelab-apps.com': '//dv360-wrapper.uat.tradelab-apps.com',
  'dv360-wrapper-admin.tradelab-apps.com': '//dv360-wrapper.tradelab-apps.com',
};

export const authentifierApiRoutes = {
  'localhost': '//authentifier-api.uat.tradelab-apps.com',
  'dv360-wrapper-admin.dev.tradelab-apps.com': '//authentifier-api.uat.tradelab-apps.com',
  'dv360-wrapper-admin.uat.tradelab-apps.com': '//authentifier-api.uat.tradelab-apps.com',
  'dv360-wrapper-admin.tradelab-apps.com': '//authentifier-api.tradelab-apps.com',
};

export const authentifierDashboardRoutes = {
  'localhost': '//authentifier.uat.tradelab-apps.com',
  'dv360-wrapper-admin.dev.tradelab-apps.com': '//authentifier.uat.tradelab-apps.com',
  'dv360-wrapper-admin.uat.tradelab-apps.com': '//authentifier.uat.tradelab-apps.com',
  'dv360-wrapper-admin.tradelab-apps.com': '//authentifier.tradelab-apps.com'
};

export const providersID = {
  'localhost': '593143003659-dq3n5j67gutpk3543beln0e22pl916gf.apps.googleusercontent.com',
  'dv360-wrapper-admin.dev.tradelab-apps.com': '593143003659-dq3n5j67gutpk3543beln0e22pl916gf.apps.googleusercontent.com',
  'dv360-wrapper-admin.uat.tradelab-apps.com': '837798551876-m6bdgdkb5q6k75di1v4ujji64mv7uvua.apps.googleusercontent.com',
  'dv360-wrapper-admin.tradelab-apps.com': '22536527414-05cqlspvgi46vj1hj0u6l2908btjak1m.apps.googleusercontent.com',
};
