import { HttpClient } from '@angular/common/http';
import { Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { Serializer } from './serializer';
import { baseUrl } from '../api.service';
import { Resource } from './resource';
import { DvQueryOptions } from './dv-query-options.service';

export abstract class DvResourceService<T extends Resource> {

  constructor(
    protected httpClient: HttpClient,
    protected endpoint: string,
    protected serializer: Serializer<T>,
    protected endpointBaseUrl: string = baseUrl,
    protected endpointOutput?: string
  ) {}

  list(queryOptions?: DvQueryOptions): Observable<T[]> {
    return new Observable((observer: Observer<T[]>) => {
      this.listPaginate(queryOptions).subscribe((result: T[]) => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  protected listPaginate(queryOptions?: DvQueryOptions, finalResult = []): Observable<T[]> {
    const url = queryOptions
      ? `${this.endpointBaseUrl}/${this.endpoint}?${queryOptions.toQueryString()}`
      : `${this.endpointBaseUrl}/${this.endpoint}`;

    return this.httpClient.get(url).pipe(
      map((data: any) => {
        const currentResult = data[this.endpointOutput || this.endpoint]
          ? this.convertData(data[this.endpointOutput || this.endpoint])
          : [];

        finalResult.push(...currentResult);

        return data;
      }),
      switchMap((data: any) => {
        if (data.nextPageToken) {
          queryOptions.nextPageToken = data.nextPageToken;
          return this.listPaginate(queryOptions, finalResult);
        } else {
          return of(finalResult);
        }
      })
    );
  }

  public convertData(data: any): T[] {
    return data.map((item: any) => this.serializer.fromJson(item));
  }
}
