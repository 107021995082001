import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(items: any[], callbackFn?: (item: any, comparator: any) => boolean, comparator?: any): any {
    if (!items || !callbackFn) {
      return items;
    }
    return items.filter(item => callbackFn(item, comparator));
  }

}
