import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private cookie: CookieService, private route: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes('/login')) {
      return next.handle(req);
    }

    const serviceToken = this.cookie.get('AuthToken');
    if (serviceToken) {
      return next.handle(req.clone({
        setHeaders: {
          'Authorization': `Bearer ${serviceToken}`
        }
      }));
    } else {
      this.route.navigate(['login']);
    }
  }
}
