import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { UserMeService } from 'app/userMe/userMe.service';
import { Observable } from 'rxjs';
import { IUser, ILoginResponse } from '@shared/interfaces/gandalf';
import { authentifierApiUrl } from '@shared/api.service';
import { map } from 'rxjs/operators';
import { UserMe } from 'app/userMe/userMe';
import { SocialUser } from 'angularx-social-login';

@Injectable()
export class LoginService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private userMeService: UserMeService
  ) { }

  onLogin(email: string, password: string): Observable<IUser> {
    return this.http.post(`${authentifierApiUrl}/login`, {email, password})
      .pipe(map((response) => this.setCookie(response as ILoginResponse)));
  }

  onLoginGoogle(userSocial: SocialUser): Observable<any> {
    return this.http.post(`${authentifierApiUrl}/login_google`, {}, {
      headers: {
        'Authorization': `Bearer ${userSocial.authToken}`
      }
    })
    .pipe(map((response) => this.setCookie(response as ILoginResponse)));
  }

  setCookie(response: ILoginResponse): IUser {
    const userMe = new UserMe(response.user);

    this.userMeService.set(userMe);

    const options: CookieOptions = {
      path: '/',
      expires: new Date(Date.now() + (12 * 60 * 60 * 1000))
    };
    this.cookie.put('AuthToken', response.token.value, options);
    return response.user;
  }

  protected handleLogout(): void {
    this.cookie.remove('AuthToken');
    localStorage.clear();
    this.router.navigate(['login']);
  }

  onLogout(): void {
    this.http.post(`${authentifierApiUrl}/logout`, {})
      .subscribe({
        next: this.handleLogout.bind(this),
        error: this.handleLogout.bind(this)
      });
  }
}
