import { Injectable } from '@angular/core';
import { UserMe } from './userMe';

@Injectable({
  providedIn: 'root'
})
export class UserMeService {
    get(): UserMe {
      return JSON.parse(localStorage.getItem('USER_ME'));
    }

    set(user: UserMe) {
      localStorage.setItem('USER_ME', JSON.stringify(user));
    }
}
