import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@shared/api.service';
import { DvResourceService } from '@shared/resources/dv-resource.service';
import { Partner } from './partner';
import { PartnerSerializer } from './partner.serializer';


@Injectable({
  providedIn: 'root'
})
export class PartnerService extends DvResourceService<Partner> {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(
      httpClient,
      'wrapper/v3/partners',
      new PartnerSerializer,
      baseUrl,
      'partners'
    );
  }
}
