import { IUser, IUserMetaData } from '@shared/interfaces/gandalf';

export class UserMe implements Partial<IUser> {
  id: number;
  email: string;
  name: string;
  metadata: IUserMetaData;
  created_at: Date;
  updated_at: Date;

  constructor (json: IUser) {
    const dates = ['created_at', 'updated_at'];
    const hasPassword = (key: string): boolean => key !== 'password';
    const setDates = (key: string): void => this[key] = dates.includes(key) ? new Date(json[key]) : json[key];

    Object.keys(json)
      .filter(hasPassword)
      .forEach(setDates);
  }
}
